import button_type1 from "./button_type1.svg";
import button_type2 from "./button_type2.svg";
import button_type3 from "./button_type3.svg";
import button_prev from "./button_prev.svg";

const button = {
    type1: button_type1,
    type2: button_type2,
    type3: button_type3,

    prev: button_prev
};

export default button;