const { GoogleSpreadsheet } = require('google-spreadsheet');

const jsonify = json => JSON.parse(json);

async function UpdateSheets() {
  const doc = new GoogleSpreadsheet(process.env.REACT_APP_SPREAD_SHEET_ID);

  await doc.useServiceAccountAuth({
    client_email: process.env.REACT_APP_CLIENT_EMAIL,
    private_key: process.env.REACT_APP_PRIVATE_KEY
  });

  const userType = Number(localStorage.getItem('userType')) - 1;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const userAnswer = JSON.parse(localStorage.getItem('userAnswer'));

  await doc.loadInfo();
  const sheet = doc.sheetsByIndex[userType];

  const key1 = jsonify(process.env.REACT_APP_KEY1)[userType];
  const key2 = process.env.REACT_APP_KEY2
  const key3 = jsonify(process.env.REACT_APP_KEY3)[userType];
  const key4 = jsonify(process.env.REACT_APP_KEY4)[userType];

  const value1 = jsonify(process.env.REACT_APP_VALUE1)[userType][userInfo['0']];
  const value2 = jsonify(process.env.REACT_APP_VALUE2)[userInfo['1']];
  const value3 = jsonify(process.env.REACT_APP_VALUE3)[userType][userInfo['2']];
  const value4 = jsonify(process.env.REACT_APP_VALUE4)[userType][userInfo['3']];

  Object.keys(userAnswer).forEach(v => { userAnswer[v] = jsonify(process.env.REACT_APP_ANSWERS)[userAnswer[v]] });

  await sheet.addRow({
    [key1]: value1,
    [key2]: value2,
    [key3]: value3,
    [key4]: value4,
    ...userAnswer
  });
}

export default UpdateSheets;