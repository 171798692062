import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../style.css";
import deco from "../res/deco/deco";
import button from "../res/button/button";
import box_default from "../res/box/box_default.svg";

const rel = { position: "relative" };
const abs = { position: "absolute" };
const row = (grav) => ({ display: "flex", flexDirection: "row", alignItems: grav });
const col = (grav) => ({ display: "flex", flexDirection: "column", alignItems: grav });

const place = (src, scale, top, left) => (
  <img style={
    {
      ...abs,
      width: (scale + "%"),
      top: (top + "%"),
      left: (left + "%"),
      transform: "translate(-50%, -50%)"
    }
  } alt="" src={src} />
);

const radioButton = (txt, pos, group) => (
  <div style={{ marginRight: "2%" }}>
    <label style={{ ...row("center"), lineHeight: "1", fontSize: "0.9em" }}>
      <input
        type="radio"
        position={pos}
        checked={pos === selected[group]}
        onChange={() => {
          selected[group] = pos;
          setSelected({ ...selected });
          if (selected[0] === undefined) setEnable(false);
          else if (selected[1] === undefined) setEnable(false);
          else if (selected[2] === undefined) setEnable(false);
          else if (selected[3] === undefined) setEnable(false);
          else setEnable(true);
        }}
        style={{
          width: "1.1em",
          height: "1.1em",
          accentColor: "#F7534A",
          marginRight: "0.4em"
        }} />
      {txt}
    </label>
  </div>
);

const info_type1 = () => (
  <div style={{ ...col("start"), width: "90%" }}>
    <div>
      <div className="solid_yellow" style={{ width: "100%", height: "40%" }} />
      <div style={{ transform: "translate(2px, calc(4px - 100%))" }}>
        <strong style={{ fontSize: "1.3em" }}>소속</strong>
      </div>
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("초등학교", 0, 0)}
      {radioButton("중학교", 1, 0)}
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("고등학교", 2, 0)}
      {radioButton("특수학교", 3, 0)}
    </div>

    <br />
    <br />

    <div>
      <div className="solid_yellow" style={{ width: "100%", height: "40%" }} />
      <div style={{ transform: "translate(2px, calc(4px - 100%))" }}>
        <strong style={{ fontSize: "1.3em" }}>본인의 성별</strong>
      </div>
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("남자", 0, 1)}
      {radioButton("여자", 1, 1)}
    </div>
    
    <br />
    <br />

    <div>
      <div className="solid_yellow" style={{ width: "100%", height: "40%" }} />
      <div style={{ transform: "translate(2px, calc(4px - 100%))" }}>
        <strong style={{ fontSize: "1.3em" }}>학년</strong>
      </div>
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("1학년", 0, 2)}
      {radioButton("2학년", 1, 2)}
      {radioButton("3학년", 2, 2)}
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("4학년", 3, 2)}
      {radioButton("5학년", 4, 2)}
      {radioButton("6학년", 5, 2)}
    </div>

    <br />
    <br />

    <div>
      <div className="solid_yellow" style={{ width: "100%", height: "40%" }} />
      <div style={{ transform: "translate(2px, calc(4px - 100%))" }}>
        <strong style={{ fontSize: "1.3em" }}>지역</strong>
      </div>
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("중구", 0, 3)}
      {radioButton("동구", 1, 3)}
      {radioButton("북구", 2, 3)}
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("강화군", 3, 3)}
      {radioButton("옹진군", 4, 3)}
      {radioButton("미추홀구", 5, 3)}
      
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("연수구", 6, 3)}
      {radioButton("남동구", 7, 3)}
      {radioButton("부평구", 8, 3)}
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("계양구", 9, 3)}
      {radioButton("서구", 10, 3)}
    </div>
  </div>
);

const info_type2 = () => (
  <div style={{ ...col("start"), width: "90%" }}>
    <div>
      <div className="solid_yellow" style={{ width: "100%", height: "40%" }} />
      <div style={{ transform: "translate(2px, calc(4px - 100%))" }}>
        <strong style={{ fontSize: "1.3em" }}>나이</strong>
      </div>
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("20대", 0, 0)}
      {radioButton("30대", 1, 0)}
      {radioButton("40대", 2, 0)}
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("50대", 3, 0)}
      {radioButton("60대 이상", 4, 0)}
    </div>

    <br />
    <br />

    <div>
      <div className="solid_yellow" style={{ width: "100%", height: "40%" }} />
      <div style={{ transform: "translate(2px, calc(4px - 100%))" }}>
        <strong style={{ fontSize: "1.3em" }}>본인의 성별</strong>
      </div>
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("남자", 0, 1)}
      {radioButton("여자", 1, 1)}
    </div>
    
    <br />
    <br />

    <div>
      <div className="solid_yellow" style={{ width: "100%", height: "40%" }} />
      <div style={{ transform: "translate(2px, calc(4px - 100%))" }}>
        <strong style={{ fontSize: "1.3em" }}>자녀의 성별</strong>
      </div>
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("남", 0, 2)}
      {radioButton("여", 1, 2)}
      {radioButton("둘 다", 2, 2)}
      {radioButton("없음", 3, 2)}
    </div>
    
    <br />
    <br />

    <div>
      <div className="solid_yellow" style={{ width: "100%", height: "40%" }} />
      <div style={{ transform: "translate(2px, calc(4px - 100%))" }}>
        <strong style={{ fontSize: "1.3em" }}>지역</strong>
      </div>
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("중구", 0, 3)}
      {radioButton("동구", 1, 3)}
      {radioButton("북구", 2, 3)}
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("강화군", 3, 3)}
      {radioButton("옹진군", 4, 3)}
      {radioButton("미추홀구", 5, 3)}
      
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("연수구", 6, 3)}
      {radioButton("남동구", 7, 3)}
      {radioButton("부평구", 8, 3)}
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("계양구", 9, 3)}
      {radioButton("서구", 10, 3)}
    </div>
  </div>
);

const info_type3 = () => (
  <div style={{ ...col("start"), width: "90%" }}>
    <div>
      <div className="solid_yellow" style={{ width: "100%", height: "40%" }} />
      <div style={{ transform: "translate(2px, calc(4px - 100%))" }}>
        <strong style={{ fontSize: "1.3em" }}>나이</strong>
      </div>
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("20대", 0, 0)}
      {radioButton("30대", 1, 0)}
      {radioButton("40대", 2, 0)}
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("50대", 3, 0)}
      {radioButton("60대 이상", 4, 0)}
    </div>

    <br />
    <br />

    <div>
      <div className="solid_yellow" style={{ width: "100%", height: "40%" }} />
      <div style={{ transform: "translate(2px, calc(4px - 100%))" }}>
        <strong style={{ fontSize: "1.3em" }}>본인의 성별</strong>
      </div>
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("남자", 0, 1)}
      {radioButton("여자", 1, 1)}
    </div>
    
    <br />
    <br />

    <div>
      <div className="solid_yellow" style={{ width: "100%", height: "40%" }} />
      <div style={{ transform: "translate(2px, calc(4px - 100%))" }}>
        <strong style={{ fontSize: "1.3em" }}>소속</strong>
      </div>
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("유치원", 0, 2)}
      {radioButton("초등학교", 1, 2)}
      {radioButton("중학교", 2, 2)}
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("고등, 특수학교(공립)", 3, 2)}
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("고등, 특수학교(사립)", 4, 2)}
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("교육(지원)청, 직속기관", 6, 2)}
    </div>

    <br />
    <br />

    <div>
      <div className="solid_yellow" style={{ width: "100%", height: "40%" }} />
      <div style={{ transform: "translate(2px, calc(4px - 100%))" }}>
        <strong style={{ fontSize: "1.3em" }}>직급</strong>
      </div>
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("교장, 교감", 0, 3)}
      {radioButton("수석교사", 1, 3)}
      {radioButton("부장교사", 2, 3)}
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("교사", 3, 3)}
      {radioButton("5급 이상 일반직", 4, 3)}
      {radioButton("6급 이하 일반직", 5, 3)}
    </div>
    <div style={{ ...row("start"), width: "150%", height: "auto", paddingInlineStart: "2%", marginBottom: "2%" }}>
      {radioButton("교육전문직", 6, 3)}
      {radioButton("교육공무직 및 기타", 7, 3)}
    </div>
  </div >
);

const select_info = () => {
  switch (localStorage.getItem("userType")) {
    case "1":
      return info_type1();
    case "2":
      return info_type2();
    case "3":
      return info_type3();
    default:
      return;
  }
};

const onClick = () => {
  if (isEnable) localStorage.setItem("userInfo", JSON.stringify(selected));
  else {
    if (selected[0] === undefined) {
      switch (localStorage.getItem("userType")) {
        case "1": return alert("소속을 선택해주세요.");
        case "2": return alert("나이를 선택해주세요.");
        case "3": return alert("나이를 선택해주세요.");
        default: return;
      }
    }
    else if (selected[1] === undefined) {
      return alert("본인의 성별을 선택해주세요.");
    }
    else if (selected[2] === undefined) {
      switch (localStorage.getItem("userType")) {
        case "1": return alert("학년을 선택해주세요.");
        case "2": return alert("자녀의 성별 선택해주세요.");
        case "3": return alert("소속을 선택해주세요.");
        default: return;
      }
    }
    else if (selected[3] === undefined) {
      switch (localStorage.getItem("userType")) {
        case "1": return alert("지역을 선택해주세요.");
        case "2": return alert("지역을 선택해주세요.");
        case "3": return alert("직급을 선택해주세요.");
        default: return;
      }
    }
  }
};

let selected, setSelected;
let isEnable, setEnable;
const Info = () => {
  [selected, setSelected] = useState({});
  [isEnable, setEnable] = useState(false);

  return (
    <div className="background1">
      <div style={{ ...rel, ...row("center"), width: "100%", height: "100%", flex: "1" }}>
        <div style={{ ...rel, ...col("center"), width: "100%", height: "100%", flex: "1" }} >
          <Link to="/">
            {place(button.prev, 35, 70, 40)}
          </Link>
        </div>
        <div style={{ ...rel, ...col("center"), width: "100%", height: "100%", flex: "2" }} >
          {place(deco[9], 60, -70, 105)} {place(deco[9], 60, -70, 105)}
          {place(deco[3], 10, 45, 0)}
          {place(deco[3], 5, 90, 15)}
          {place(deco[3], 3, 110, 90)}
          {place(deco[2], 25, 68, 70)}
        </div>
      </div>
      <div style={{ ...rel, ...col("center"), justifyContent: "center", height: "auto", flex: "7" }}>
        <img src={box_default} alt="" width="100%" />
        <div style={{ ...abs, ...col("center"), width: "80%", height: "80%" }}>
          <div style={{ ...rel, ...col("center"), justifyContent: "center", width: "100%", height: "100%", flex: "5" }}>
            {select_info()}
          </div>
          <div style={{ ...col("center"), width: "100%", height: "0", flex: "1", textAlign: "center" }}>
            <Link to={isEnable ? "/questions" : "#"} style={{ display: "flex", width: "60%", height: "auto", textDecorationLine: "none" }}>
              <font className="solid_red border_black" style={{ height: "auto", paddingTop: "7px", paddingBottom: "7px" }} onClick={onClick}>
                <strong style={{ fontSize: "1.2em" }}>시작하기</strong>
              </font>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;