const QuestionList = {
    "1": [
        {
            question: "여자는 다이어트, 화장 등으로 외모를 예쁘게 가꾸는 것이 좋다.",
            positive: 0,
            type: 0
        },
        {
            question: "남학생은 수학을 잘하고 여학생은 국어를 잘한다는 것은 고정관념이다.",
            positive: 1,
            type: 0
        },
        {
            question: "여자가 추워하면 남자가 옷을 벗어주는 것이 바람직하다.",
            positive: 0,
            type: 0
        },
        {
            question: "'된장녀', '맘충', 'OO녀' 등의 단어는 여성을 비하하는 혐오표현이다.",
            positive: 1,
            type: 1
        },
        {
            question: "여자는 노력이 아닌 자신의 미모와 애교를 이용해서 남성으로부터 자신이 필요한 것을 얻어내려 한다.",
            positive: 0,
            type: 1
        },
        {
            question: "학교에서 남학생이 여학생보다 대우를 덜 받고 역차별을 당한다고 생각한다.",
            positive: 0,
            type: 1
        },
        {
            question: "남자가 성관계를 요구할 때 여자가 거부하지 않으면 수락한 것이다.",
            positive: 0,
            type: 2
        },
        {
            question: "여성들의 노출이 많은 옷차림이 불법촬영이나 성추행 등의 원인이 될 수 있다는 것은 잘못된 통념이다.",
            positive: 1,
            type: 2
        },
        {
            question: "애인의 옷차림이나 인간관계, 귀가 시간을 통제하는 것은 당연하다.",
            positive: 0,
            type: 2
        },
        {
            question: "아동, 청소년을 이용한 성착취물(신체노출 사진, 영상 등)을 혼자 가지고만 있는 것도 처벌되는 것은 지나치다고 생각한다.",
            positive: 0,
            type: 3
        },
        {
            question: "우리나라 남자 청소년 중에 음란물을 보지 않는다는 사람이 있다면 거짓말일 것이다.",
            positive: 0,
            type: 3
        },
        {
            question: "여러 명이 모여서 야한 동영상이나 불법 촬영된 사진, 영상 등을 보는 플랫폼이나 사이트에서 시청만 하는 것도 처벌 받아야 한다고 생각한다.",
            positive: 1,
            type: 3
        }
    ],

    "2": [
        {
            question: "여성에게 외모 가꾸기를 강요하는 것은 성차별이다.",
            positive: 1,
            type: 0
        },
        {
            question: "여성은 모성애를 타고나기 때문에 육아나 가정을 꾸리는 것은 여성이 해야 한다고 생각한다.",
            positive: 0,
            type: 0
        },
        {
            question: "여학생이 길에서 담배를 피우는 것은 남학생에 비해 더 보기 싫다.",
            positive: 0,
            type: 0
        },
        {
            question: "데이트 비용, 결혼 준비 비용은 남녀가 동등하게 준비해야 한다.",
            positive: 1,
            type: 1
        },
        {
            question: "맞벌이를 해도 가사일과 자녀양육은 여자에게 더 책임이 크다고 생각한다.",
            positive: 0,
            type: 1
        },
        {
            question: "남자가 여자보다 고위직에 많은 이유는 여성보다 그만큼 힘든 일을 하고 능력이 있기 때문이다.",
            positive: 0,
            type: 1
        },
        {
            question: "여자들은 별 뜻 없이 한 말이나 행동도 성희롱이나 성폭력이라고 예민하게 받아들인다.",
            positive: 0,
            type: 2
        },
        {
            question: "여성들의 노출이 많은 옷차림은 불법촬영이나 성추행 등의 원인이 될 수 없다.",
            positive: 1,
            type: 2
        },
        {
            question: "가정폭력은 개인의 사적인 일이므로 이웃이 개입해서는 안 된다.",
            positive: 0,
            type: 2
        },
        {
            question: "아동, 청소년을 이용한 성착취물(신체노출 사진, 영상 등)을 혼자 가지고만 있는 것도 처벌되는 것은 지나치다고 생각한다.",
            positive: 0,
            type: 3
        },
        {
            question: "우리나라 남자 청소년 중에 음란물을 보지 않는다는 사람이 있다면 거짓말일 것이다.",
            positive: 0,
            type: 3
        },
        {
            question: "여러 명이 모여서 야한 동영상이나 불법 촬영된 사진, 영상 등을 보는 플랫폼이나 사이트에서 시청만 하는 것도 처벌 받아야만 한다고 생각한다.",
            positive: 1,
            type: 3
        }
    ],

    "3": [
        {
            question: "여자는 다이어트, 화장 등으로 외모를 예쁘게 가꾸는 것이 좋다.",
            positive: 0,
            type: 0
        },
        {
            question: "남학생은 수학을 잘하고 여학생은 국어를 잘한다는 것은 고정관념이다.",
            positive: 1,
            type: 0
        },
        {
            question: "여학생이 길에서 담배를 피우는 것은 남학생에 비해 더 보기 싫다.",
            positive: 0,
            type: 0
        },
        {
            question: "'된장녀', '맘충', 'OO녀' 등의 단어는 여성을 비하하는 혐오표현이다.",
            positive: 1,
            type: 1
        },
        {
            question: "여자는 노력이 아닌 자신의 미모와 애교를 이용해서 남성으로부터 자신이 필요한 것을 얻어내려 한다.",
            positive: 0,
            type: 1
        },
        {
            question: "여성을 위한 정책을 계속 만들어내는데도 여자들은 끊임없이 더 많은 것을 요구한다.",
            positive: 0,
            type: 1
        },
        {
            question: "여자들은 별 뜻 없이 한 말이나 행동도 성희롱이나 성폭력이라고 예민하게 받아들인다.",
            positive: 0,
            type: 2
        },
        {
            question: "여성들의 노출이 많은 옷차림이 불법촬영이나 성추행 등의 원인이 될 수 없다.",
            positive: 1,
            type: 2
        },
        {
            question: "성폭력 피해자는 사건이 일어나면 반드시 신고할 것이다.",
            positive: 0,
            type: 2
        },
        {
            question: "아동, 청소년을 이용한 성착취물(신체노출 사진, 영상 등)을 혼자 가지고만 있는 것도 처벌되는 것은 지나치다고 생각한다.",
            positive: 0,
            type: 3
        },
        {
            question: "우리나라 남자 청소년 중에 음란물을 보지 않는다는 사람이 있다면 거짓말일 것이다.",
            positive: 0,
            type: 3
        },
        {
            question: "여러 명이 모여서 야한 동영상이나 불법 촬영된 사진, 영상 등을 보는 플랫폼이나 사이트에서 시청만 하는 것도 처벌 받아야 한다고 생각한다.",
            positive: 1,
            type: 3
        }
    ]
};

export default QuestionList;