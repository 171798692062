import React from "react";
import { Routes, Route } from "react-router-dom";

import Main from "./pages/Main";
import Intro from "./pages/Intro";
import Info from "./pages/Info";
import Questions from "./pages/Questions";
import Result from "./pages/Result";

import "./style.css"

function App() {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div className="App">
        <Routes>
          <Route path="/intro" element={<Intro />} />
          <Route path="/info" element={<Info />} />
          <Route path="/questions" element={<Questions />} />
          <Route path="/result" element={<Result />} />
          <Route path="*" element={<Main />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;