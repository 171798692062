import React, { useState } from "react";
import { Link } from "react-router-dom";
import QuestionList from "../QuestionList";
import UpdateSheets from "../UpdateSheets";

import "../style.css";
import deco from "../res/deco/deco";
import button from "../res/button/button";
import box_question from "../res/box/box_question.svg";

const rel = { position: "relative" };
const abs = { position: "absolute" };
const row = grav => ({ display: "flex", flexDirection: "row", alignItems: grav });
const col = grav => ({ display: "flex", flexDirection: "column", alignItems: grav });

const place = (src, scale, top, left) => (
  <img style={
    {
      ...abs,
      width: (scale + "%"),
      top: (top + "%"),
      left: (left + "%"),
      transform: "translate(-50%, -50%)"
    }
  } alt="" src={src} />
);

const radioButton = (txt, pos, group) => (
  <div style={{ marginRight: "0%", width: "100%", marginTop: "7%", marginBottom: "7%" }}>
    <label style={{ ...row("center"), width: "100%", lineHeight: "1", fontSize: "1.1em" }}>
      <input
        type="radio"
        position={pos}
        checked={pos === userAnswer["Q" + group]}
        onChange={() => {
          userAnswer["Q" + group] = pos;
          updateUserAnswer({ ...userAnswer });
          if (group === QuestionList[localStorage.getItem("userType")].length) setEnable(true);
        }}
        style={{
          width: "1.6em",
          height: "1.6em",
          accentColor: "#F7534A",
          marginRight: "0.7em"
        }} />
      {txt}
    </label>
  </div>
);

const nextPage = () => {
  if (userAnswer["Q" + page] === undefined) return alert("응답을 선택해주세요.");
  if (page === QuestionList[localStorage.getItem("userType")].length) {
    localStorage.setItem("userAnswer", JSON.stringify(userAnswer));
    UpdateSheets();
  }
  setPage(page + 1);
};

const prevPage = () => {
  setPage(page - 1);
  setEnable(false);
}

let userAnswer, updateUserAnswer;
let page, setPage;
let isEndable, setEnable;
const Questions = () => {
  [userAnswer, updateUserAnswer] = useState({});
  [page, setPage] = useState(1);
  [isEndable, setEnable] = useState(false);

  return (
    <div className="background2">
      <div style={{ ...rel, ...row("center"), width: "100%", height: "100%", flex: "1" }}>
        <div style={{ ...rel, ...col("center"), width: "100%", height: "100%", flex: "1" }} >
          <Link to={page === 1 ? "/intro" : "#"} onClick={prevPage}>
            {place(button.prev, 35, 70, 40)}
          </Link>
        </div>
        <div style={{ ...rel, ...col("center"), width: "100%", height: "100%", flex: "2" }} >
          {place(deco[1], 20, 95, 8)}
          {place(deco[7], 3, 40, 20)}
          {place(deco[8], 3, 85, 50)}
          {place(deco[3], 20, 50, 75)}
        </div>
      </div>
      <div style={{ ...rel, ...col("center"), justifyContent: "center", height: "auto", flex: "7" }}>
        <img src={box_question} alt="" width="100%" />
        <div style={{ ...abs, ...col("center"), width: "80%", height: "80%" }}>
          <div style={{ ...rel, ...col("center"), width: "100%", justifyContent: "center", flex: "5" }}>
            <div style={{ ...col("center"), width: "87.5%", flex: "1" }}>
              <div style={{ ...col("center"), flex: "1", justifyContent: "end" }}>
                <strong style={{ fontSize: "1.5em", color: "#F7534A" }}>{`Q${page}.`}</strong>
              </div>
              <div style={{ ...col("center"), flex: "2", justifyContent: "center", textAlign: "center", wordBreak: "keep-all", paddingTop: "5px" }}>
                <strong style={{ fontSize: "1.2em", lineHeight: "1.3" }}>{QuestionList[localStorage.getItem("userType")][page - 1].question}</strong>
              </div>
            </div>
            <div style={{ ...col("start"), width: "60%", flex: "2" }}>
              {radioButton("매우 그렇다", 0, page)}
              <div className="solid_yellow" style={{ width: "100%", height: "1px" }} />
              {radioButton("그렇다", 1, page)}
              <div className="solid_yellow" style={{ width: "100%", height: "1px" }} />
              {radioButton("보통", 2, page)}
              <div className="solid_yellow" style={{ width: "100%", height: "1px" }} />
              {radioButton("그렇지 않다", 3, page)}
              <div className="solid_yellow" style={{ width: "100%", height: "1px" }} />
              {radioButton("전혀 그렇지 않다", 4, page)}
            </div>
          </div>
          <div style={{ ...col("center"), width: "100%", height: "0", flex: "1", textAlign: "center" }}>
            <Link to={isEndable ? "/result" : "#"} style={{ display: "flex", width: "60%", height: "auto", textDecorationLine: "none" }}>
              <font className="solid_green border_black" style={{ height: "auto", paddingTop: "7px", paddingBottom: "7px" }} onClick={nextPage}>
                <strong style={{ fontSize: "1.2em" }}>다음</strong>
              </font>
            </Link>
            <div style={{ ...col("center"), justifyContent: "center", width: "60%", height: "100%" }}>
              <div className="border_black" style={{ ...row("center"), height: "15%", transform: "translateY(100%)" }}>
                <div className="solid_red border_black" style={{ width: (page / QuestionList[localStorage.getItem("userType")].length * 100) + "%" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;