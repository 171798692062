import React, { useState } from "react";
import { Link } from "react-router-dom";
import QuestionList from "../QuestionList";

import "../style.css";
import deco from "../res/deco/deco";
import button from "../res/button/button";
import box_default from "../res/box/box_default.svg";

import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  RadialLinearScale
} from "chart.js";

ChartJS.register(LineElement, PointElement, RadialLinearScale);
ChartJS.defaults.font.size = 8;

const rel = { position: "relative" };
const abs = { position: "absolute" };
const row = (grav) => ({ display: "flex", flexDirection: "row", alignItems: grav });
const col = (grav) => ({ display: "flex", flexDirection: "column", alignItems: grav });

const place = (src, scale, top, left) => (
  <img style={
    {
      ...abs,
      width: (scale + "%"),
      top: (top + "%"),
      left: (left + "%"),
      transform: "translate(-50%, -50%)"
    }
  } alt="" src={src} />
);

const options = {
  scales: {
    r: {
      min: 0,
      max: 15,
      ticks: {
        stepSize: 5
      }
    }
  }
};

let data, setData;
const Result = () => {
  const userAnswer = JSON.parse(localStorage.getItem("userAnswer"));

  [data, setData] = useState({
    labels: ["", "", "", ""],
    datasets: [{
      data: [0, 0, 0, 0],
      borderColor: "rgb(247, 83, 74)",
      borderWidth: 1,
    }]
  });

  const scores = (() => {
    let scores = [0, 0, 0, 0];

    QuestionList[localStorage.getItem("userType")].forEach((v, i) => {
      if(v.positive) {
        scores[v.type] += 5 - userAnswer["Q"+(i+1)];
      } else {
        scores[v.type] += 1 + userAnswer["Q"+(i+1)];
      }
    })
    
    return scores;
  })();

  setTimeout(() => {
    setData({
      labels: ["", "", "", ""],
      datasets: [{
        data: scores,
        borderColor: "rgb(247, 83, 74)",
        borderWidth: 1,
      }]
    })
  }, 1500);

  return (
    <div className="background1">
      <div style={{ ...rel, ...row("center"), width: "100%", height: "100%", flex: "1" }}>
        <div style={{ ...rel, ...col("center"), width: "100%", height: "100%", flex: "1" }} >
          <Link to="/">
            {place(button.prev, 35, 70, 40)}
          </Link>
        </div>
        <div style={{ ...rel, ...col("center"), width: "100%", height: "100%", flex: "2" }} >
          {place(deco[9], 60, -70, 105)} {place(deco[9], 60, -70, 105)}
          {place(deco[3], 10, 45, 0)}
          {place(deco[3], 5, 90, 15)}
          {place(deco[3], 3, 110, 90)}
          {place(deco[2], 25, 68, 70)}
        </div>
      </div>
      <div style={{ ...rel, ...col("center"), justifyContent: "center", height: "auto", flex: "7" }}>
        <img src={box_default} alt="" width="100%" />
        <div style={{ ...abs, ...col("center"), width: "80%", height: "80%" }}>
          <div style={{ ...rel, ...col("center"), justifyContent: "center", width: "100%", height: "100%", flex: "5" }}>
            <br />
            <font style={{ fontSize: "1.7em" }}><strong>나의 성인지 감수성 정도는?</strong></font>
            <p className="solid_yellow border_yellow" style={{ width: "auto", height: "auto", padding: "5px 10px 5px 10px" }}>
              {(() => {
                let average = scores.reduce((a, b) => a + b) / 4;
                if (average > 10) return "훌륭해요!";
                else if (average > 5) return "좋아요!";
                else return "노력해요!";
              })()}
            </p>
            <br />
            <div className="border_yellow" style={{ ...rel, width: "90%", height: "50%", textAlign: "center", wordBreak: "keep-all", fontSize: "0.8em", lineHeight: "1.1" }}>
              {place(deco[1], 25, 0, 87.5)}
              {place(deco[12], 12.5, 87.5, 0)}
              <div style={{ ...abs, ...col("center"), width: "90%", height: "90%", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <div style={{ ...col("center"), width: "100%", transform: "translateY(100%)" }}>
                  <font>성별 고정관념</font>
                </div>
                <div style={{ ...row("center"), width: "100%", height: "100%", justifyContent: "center", textAlign: "center" }}>
                  <div style={{ ...col("center"), width: "25%" }}>
                    <font>온라인<br />성인지 감수성</font>
                  </div>
                  <div style={{ ...rel, ...col("center"), width: "50%" }}>
                    <div style={{ ...abs, width: "120%", height: "auto", transform: "translateY(-50%)" }}>
                      <Radar data={data} options={options} style={{ width: "100%", height: "100%" }} />
                    </div>
                  </div>
                  <div style={{ ...col("center"), width: "25%" }}>
                    <font>성차별 문화<br />및 사회구조</font>
                  </div>
                </div>
                <div style={{ ...col("center"), width: "100%", transform: "translateY(-50%)" }}>
                  <font>젠더폭력에 대한<br />통념 및 인식</font>
                </div>
              </div>
            </div>
          </div>
          <div style={{ ...col("center"), width: "100%", height: "0", flex: "1", textAlign: "center" }}>
            <Link to="/" style={{ display: "flex", width: "60%", height: "auto", textDecorationLine: "none" }}>
              <font className="solid_red border_black" style={{ height: "auto", paddingTop: "7px", paddingBottom: "7px" }}>
                <strong style={{ fontSize: "1.2em" }}>처음으로</strong>
              </font>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;