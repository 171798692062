import deco1 from "./deco1.png";
import deco2 from "./deco2.svg";
import deco3 from "./deco3.svg";
import deco4 from "./deco4.svg";
import deco5 from "./deco5.svg";
import deco6 from "./deco6.svg";
import deco7 from "./deco7.svg";
import deco8 from "./deco8.svg";
import deco9 from "./deco9.svg";
import deco10 from "./deco10.svg";
import deco11 from "./deco11.svg";
import deco12 from "./deco12.svg";

const deco = [
    null,
    deco1,
    deco2,
    deco3,
    deco4,
    deco5,
    deco6,
    deco7,
    deco8,
    deco9,
    deco10,
    deco11,
    deco12
];

export default deco;