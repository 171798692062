import React from "react";
import { Link } from "react-router-dom";

import "../style.css";
import logo from "../res/logo.png"
import deco from "../res/deco/deco";
import button from "../res/button/button";
import box_title from "../res/box/box_title.svg";

const rel = { position: "relative" };
const abs = { position: "absolute" };
const row = (grav) => ({ display: "flex", flexDirection: "row", alignItems: grav });
const col = (grav) => ({ display: "flex", flexDirection: "column", alignItems: grav });

const userType = type => {
  localStorage.clear();
  localStorage.setItem("userType", type);
}

const place = (src, scale, top, left) => (
  <img style={
    {
      ...abs,
      width: (scale + "%"),
      top: (top + "%"),
      left: (left + "%"),
      transform: "translate(-50%, -50%)"
    }
  } alt="" src={src} />
);

const Main = () => {
  return (
    <div className="background1">
      <div style={{ ...rel, ...row("center"), justifyContent: "center", width: "100%", height: "100%", flex: "3" }} >
        {place(deco[3], 10, 8, 35)}
        {place(deco[6], 4, 20, 55)}
        {place(deco[2], 17, 12, 75)}
        {place(deco[1], 30, 25, 14)}
        {place(deco[5], 35, 34, 90)}
        {place(deco[9], 40, 65, 19.5)}
        {place(box_title, 95, 55, 50)}
        {place(deco[3], 5, 47, 19)}
        {place(deco[3], 3, 44, 23)}
        {place(deco[3], 4, 63, 81)}
        {place(deco[4], 25, 73, 25)}
        {place(deco[10], 10, 73, 87)}
        {place(deco[7], 4, 84, 14)}
        {place(deco[1], 30, 87, 67)}
        {place(deco[8], 4, 95, 86)}
      </div>

      <div style={{ ...rel, ...row("start"), width: "100%", height: "100%", flex: "1" }}>
        <div style={{ ...col("start"), width: "0", height: "0", flex: "1", marginLeft: "30px" }}>
          <Link to="/intro" style={{ display: "flex" }}>
            <img src={button.type1} alt="" width="100%" onClick={() => userType("1")} />
          </Link>
        </div>

        <div style={{ ...col("start"), width: "0", height: "0", flex: "1" }}>
          <Link to="/intro" style={{ display: "flex" }}>
            <img src={button.type2} alt="" width="100%" onClick={() => userType("2")} />
          </Link>
        </div>

        <div style={{ ...col("start"), width: "0", height: "0", flex: "1", marginRight: "30px" }}>
          <Link to="/intro" style={{ display: "flex" }}>
            <img src={button.type3} alt="" width="100%" onClick={() => userType("3")} />
          </Link>
        </div>

        {place(logo, 15, 80, 50)}
      </div>
    </div>
  );
};

export default Main;