import React from "react";
import { Link } from "react-router-dom";

import "../style.css";
import deco from "../res/deco/deco";
import button from "../res/button/button";
import box_default from "../res/box/box_default.svg";

const rel = { position: "relative" };
const abs = { position: "absolute" };
const row = (grav) => ({ display: "flex", flexDirection: "row", alignItems: grav });
const col = (grav) => ({ display: "flex", flexDirection: "column", alignItems: grav });

const place = (src, scale, top, left) => (
  <img style={
    {
      ...abs,
      width: (scale + "%"),
      top: (top + "%"),
      left: (left + "%"),
      transform: "translate(-50%, -50%)"
    }
  } alt="" src={src} />
);

const Intro = () => {
  return (
    <div className="background1">
      <div style={{ ...rel, ...row("center"), width: "100%", height: "100%", flex: "1" }}>
        <div style={{ ...rel, ...col("center"), width: "100%", height: "100%", flex: "1" }} >
          <Link to="/">
            {place(button.prev, 35, 70, 40)}
          </Link>
        </div>
        <div style={{ ...rel, ...col("center"), width: "100%", height: "100%", flex: "2" }} >
          {place(deco[9], 60, -70, 105)} {place(deco[9], 60, -70, 105)}
          {place(deco[3], 10, 45, 0)}
          {place(deco[3], 5, 90, 15)}
          {place(deco[3], 3, 110, 90)}
          {place(deco[2], 25, 68, 70)}
        </div>
      </div>
      <div style={{ ...rel, ...col("center"), justifyContent: "center", height: "auto", flex: "7" }}>
        <img src={box_default} alt="" width="100%" />
        <div style={{ ...abs, ...col("center"), width: "80%", height: "80%" }}>
          <div style={{ ...rel, ...col("center"), justifyContent: "center", width: "100%", height: "100%", flex: "5" }}>
            <br />
            <font style={{ fontSize: "2em" }}><strong>성인지 감수성 체크!</strong></font>
            <p className="solid_yellow border_yellow" style={{ width: "auto", height: "auto", padding: "5px 10px 5px 10px" }}>
              나의 성인지 감수성 정도는?
            </p>
            <br />
            <br />
            <div className="border_yellow" style={{ ...rel, width: "80%", height: "auto", textAlign: "center", lineHeight: "1.1" }}>
              <br />
              <br />
              {place(deco[11], 20, 0, 13)}
              {place(deco[12], 15, 87, 100)}
              <p>
                정답을 찾기보다는<strong> 평소</strong><br />
                <strong>자신의 생각과 행동에</strong><br />
                <strong>가까운 것</strong>에 체크하세요.
              </p>
              <p>
                진단 결과는 응답자가
                <br />
                스스로 점검하는 것외에
                <br />
                다른 용도로 사용되지 않습니다.
              </p>
              <p>
                총 12문항으로 1~2분 소요됩니다.
              </p>
              <br />
              <br />
            </div>
          </div>
          <div style={{ ...col("center"), width: "100%", height: "0", flex: "1", textAlign: "center" }}>
            <Link to="/info" style={{ display: "flex", width: "60%", height: "auto", textDecorationLine: "none" }}>
              <font className="solid_red border_black" style={{ height: "auto", paddingTop: "7px", paddingBottom: "7px" }}>
                <strong style={{ fontSize: "1.2em" }}>시작하기</strong>
              </font>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;